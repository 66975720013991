import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';

import routes from '~/configs/routes';
import { convertCurrency } from '~/configs';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestDestroyCharging, requestUpdateCharging } from '~/services/charging';

function ModalCharging({ show, setShow, charging, callback }) {
    const [amount, setAmount] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Xóa thẻ
    const handleDestroy = async () => {
        if (!charging._id) {
            return alertError('Thẻ cào này không tồn tại');
        }

        dispatch(startLoading());
        const result = await requestDestroyCharging(null, charging._id);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(routes.login);
        } else if (result.status === 200) {
            setShow(false);
            alertSuccess(result.message);
            callback('delete', charging._id);
        } else {
            alertError(result.error);
        }
    };

    // Cập nhật thẻ
    const handleUpdateCharging = async (type) => {
        dispatch(startLoading());

        // Kiểm tra mệnh giá nếu type là 'wrong'
        if (type === 'wrong' && !amount) {
            dispatch(stopLoading());
            return alertError('Vui lòng chọn mệnh giá đúng');
        }

        // Cấu hình dữ liệu cho mỗi loại type
        const typeConfig = {
            wrong: { data: { amount }, callbackKey: 'wrong' },
            fault: { data: {}, callbackKey: 'fault' },
            success: { data: {}, callbackKey: 'success' },
            ip: { data: {}, callbackKey: 'ip' },
        };

        // Kiểm tra type hợp lệ
        if (!typeConfig[type]) {
            dispatch(stopLoading());
            return alertError('Hành động không hợp lệ thử lại sau');
        }

        // Gửi yêu cầu cập nhật
        const { data, callbackKey } = typeConfig[type];
        const result = await requestUpdateCharging(type, charging._id, data);

        dispatch(stopLoading());

        // Xử lý kết quả trả về
        const handleResponse = (result) => {
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                setShow(false);
                alertSuccess(result.message);
                if (callbackKey) {
                    callback(callbackKey, result.data);
                }
            } else {
                alertError(result.error);
            }
        };

        handleResponse(result);
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-90w" backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Yêu cầu: {charging.request}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="my-4">
                <div className="table-responsive">
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Thông tin thẻ</th>
                                <th>Trạng thái</th>
                                <th>Khai/Thực</th>
                                <th>Phí/Phạt</th>
                                <th>Tiền nhận</th>
                                <th>IP khách</th>
                                <th>Hành động</th>
                            </tr>
                        </thead>
                        <tbody>
                            {charging ? (
                                <tr>
                                    <td>
                                        <span>{charging.telco}</span>
                                        <br />
                                        <span>M: {charging.code}</span>
                                        <br />
                                        <span>S: {charging.serial}</span>
                                    </td>
                                    <td>
                                        <Badge
                                            bg={
                                                charging.status === 1
                                                    ? 'success'
                                                    : charging.status === 2
                                                    ? 'info'
                                                    : charging.status === 99 ||
                                                      charging.status === 101 ||
                                                      charging.status === 103 ||
                                                      charging.status === 104
                                                    ? 'warning'
                                                    : 'danger'
                                            }
                                        >
                                            {charging.message}
                                        </Badge>
                                    </td>
                                    <td>
                                        <b>{convertCurrency(charging.declared_value)}</b>
                                        <br />
                                        <span className="text-success">{convertCurrency(charging.value)}</span>
                                    </td>
                                    <td>{charging.fees}%</td>
                                    <td className="text-info">{convertCurrency(charging.amount)}</td>
                                    <td>
                                        <span>{charging.ip}</span>
                                        <br />
                                        <span>{charging.request?.ip}</span>
                                        <br />
                                        <span>{charging.request?.address}</span>
                                    </td>
                                    <td>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            className="mr-2"
                                            title="Xóa thẻ sẽ không thể khôi phục lại"
                                            onClick={handleDestroy}
                                        >
                                            Xóa thẻ
                                        </Button>
                                        <Button
                                            size="sm"
                                            title={
                                                charging.ip_block
                                                    ? 'Bỏ chặn IP khách hàng sẽ được phép gửi thẻ'
                                                    : 'Chặn IP khách hàng không được phép gửi thẻ'
                                            }
                                            onClick={() => handleUpdateCharging('ip')}
                                        >
                                            {charging.ip_block ? 'Bỏ chặn IP' : 'Chặn IP'}
                                        </Button>
                                    </td>
                                </tr>
                            ) : (
                                <tr>
                                    <td colSpan={8}>Không có dữ liệu</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <Form.Group className="mt-4 mt-md-5">
                        <Form.Label>
                            <b>Ghi chú:</b>
                        </Form.Label>
                        <textarea className="form-control" disabled defaultValue={charging.description} />
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-md-start">
                <Row className="col-12 justify-content-between">
                    <Col md={2} className="col-8 px-0 mr-md-3">
                        <select className="form-control" value={amount} onChange={(e) => setAmount(e.target.value)}>
                            <option value="">Chọn mệnh giá đúng</option>
                            <option value="20000">20.000đ</option>
                            <option value="50000">50.000đ</option>
                            <option value="100000">100.000đ</option>
                            <option value="200000">200.000đ</option>
                            <option value="300000">300.000đ</option>
                            <option value="500000">500.000đ</option>
                            <option value="1000000">1.000.000đ</option>
                        </select>
                    </Col>
                    <Button className="mr-md-2 mr-0 ml-2 ml-md-0" onClick={() => handleUpdateCharging('wrong')}>
                        Sai mệnh giá
                    </Button>
                    <Button className="mr-2 mt-2 mt-md-0" variant="danger" onClick={() => handleUpdateCharging('fault')}>
                        Thẻ lỗi
                    </Button>
                    <Button variant="success" className="mt-md-0 mt-2" onClick={() => handleUpdateCharging('success')}>
                        Thẻ đúng
                    </Button>
                    <Col className="mt-2 mt-md-0 col-0 pr-0">
                        <Button variant="secondary" onClick={() => setShow(false)} className="float-right">
                            Đóng
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalCharging;
