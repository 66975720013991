import request from '~/utils';

export const requestGetAdvertises = async (page) => {
    try {
        const res = await request.get('/advertises', {
            params: {
                page,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestCreateAdvertise = async (data) => {
    try {
        const res = await request.post('/advertises/create', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestDestroyAdvertise = async (id) => {
    try {
        const res = await request.delete('/advertises/destroy', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
