import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

import config from '~/configs';
import { requestUpdateGame } from '~/services/game';
import { logoutSuccess } from '~/redux/reducer/auth';
import { requestUploadImage } from '~/services/upload';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';

const { login } = config.routes;

function ModalChannel({ show, setShow, channel }) {
    const [bonus, setBonus] = useState('');
    const [value, setValue] = useState('');
    const [amount, setAmount] = useState('');
    const [nameAward, setNameAward] = useState('');
    const [serialFormat, setSerialFormat] = useState('');

    const [name, setName] = useState('');
    const [iconUrl, setIconUrl] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [codeLength, setCodeLength] = useState('');
    const [serialLength, setSerialLength] = useState('');

    const iconRef = useRef();
    const imageRef = useRef();

    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const serialFormat = channel.serial_format.join(', ');
        const value = channel.awards.map((award) => award.value).join(', ');
        const bonus = channel.awards.map((award) => award.bonus).join(', ');
        const amount = channel.awards.map((award) => award.amount).join(', ');

        setAmount(amount);
        setBonus(bonus);
        setValue(value);

        setSerialFormat(serialFormat);
        setNameAward(channel.awards[0].name);

        setName(channel.name);
        setIconUrl(channel.icon_url);
        setImageUrl(channel.image_url);
        setCodeLength(channel.code_length);
        setSerialLength(channel.serial_length);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectImage = async (type, e) => {
        dispatch(startLoading());
        const formData = new FormData();
        formData.append('image', e.target.files[0]);

        const result = await requestUploadImage(formData);

        dispatch(stopLoading());
        if (result.status === 200) {
            if (type === 'image_url') {
                setImageUrl(result.data);
            }
            if (type === 'icon_url') {
                setIconUrl(result.data);
            }
        } else {
            alertError(result.error);
        }
    };

    const handleUpdateSoftCard = async () => {
        if (!id) {
            return alertError('ID trò chơi không tồn tại');
        }
        if (!name) {
            return alertError('Tên thẻ không được để trống');
        }
        if (!imageUrl) {
            return alertError('Ảnh thẻ không được để trống');
        }
        if (!iconUrl) {
            return alertError('Ảnh icon thẻ không được để trống');
        }
        if (!codeLength) {
            return alertError('Độ dài mã thẻ không được để trống');
        }
        if (!serialLength) {
            return alertError('Độ dài serial thẻ không được để trống');
        }

        const valueArray = value.split(',').map((item) => item.trim());
        const bonusArray = bonus.split(',').map((item) => item.trim());
        const amountArray = amount.split(',').map((item) => item.trim());
        const serialArray = serialFormat.split(',').map((item) => item.trim());

        if (valueArray.length !== bonusArray.length || bonusArray.length !== amountArray.length) {
            return alertError('Mệnh giá thẻ, phần thưởng, thưởng thêm phải như nhay');
        }
        if (serialArray.length < 1) {
            return alertError('Tối thiểu có 1 định dạng serial thẻ');
        }

        const awards = [];
        for (let i = 0; i < valueArray.length; i++) {
            awards.push({
                name: nameAward,
                value: parseInt(valueArray[i]),
                bonus: parseInt(bonusArray[i]),
                amount: parseInt(amountArray[i]),
            });
        }

        dispatch(startLoading());
        const data = {
            name,
            awards,
            icon_url: iconUrl,
            code: channel.code,
            image_url: imageUrl,
            priority: channel.priority,
            serial_format: serialArray,
            code_length: parseInt(codeLength),
            serial_length: parseInt(serialLength),
        };

        const result = await requestUpdateGame(data, id, 'channels');

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            setShow(false);
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false} size="xl">
            <Modal.Header>
                <Modal.Title style={{ fontSize: '2rem' }}>Sửa thẻ {channel.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="my-4">
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Tên</Form.Label>
                            <Form.Control placeholder="Nhập tên thẻ" value={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Mã code thẻ</Form.Label>
                            <Form.Control placeholder="Nhập mã code" defaultValue={channel.code} disabled />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Độ dài mã thẻ</Form.Label>
                            <Form.Control
                                placeholder="Nhập độ dài mã thẻ"
                                value={codeLength}
                                onChange={(e) => setCodeLength(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Độ dài serial thẻ</Form.Label>
                            <Form.Control
                                placeholder="Nhập độ dài serial thẻ"
                                value={serialLength}
                                onChange={(e) => setSerialLength(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Mệnh giá</Form.Label>
                            <Form.Control
                                placeholder="Cách nhau bởi dấu phẩy (,)"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Phần thưởng</Form.Label>
                            <Form.Control
                                placeholder="Cách nhau bởi dấu phẩy (,)"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Thưởng thêm</Form.Label>
                            <Form.Control
                                placeholder="Cách nhau bởi dấu phẩy (,)"
                                value={bonus}
                                onChange={(e) => setBonus(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Tên phần tưởng</Form.Label>
                            <Form.Control placeholder="Tên phần thưởng" value={nameAward} onChange={(e) => setNameAward(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-5">
                            <Form.Label>Định dạng serial</Form.Label>
                            <Form.Control
                                placeholder="Nhập độ dài serial thẻ"
                                value={serialFormat}
                                onChange={(e) => setSerialFormat(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-5">
                            <Form.Label>Ảnh</Form.Label>
                            <Button size="sm" variant="info" className="ml-3 mb-2" onClick={() => imageRef.current.click()}>
                                Chọn ảnh
                            </Button>
                            <input type="file" ref={imageRef} onChange={(e) => handleSelectImage('image_url', e)} hidden />
                            <div className="mt-2 ml-5">
                                <img src={imageUrl} alt={name} style={{ border: '1px solid #ececec', width: 100, height: 70 }} />
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Icon</Form.Label>
                            <Button size="sm" variant="info" className="ml-3 mb-2" onClick={() => iconRef.current.click()}>
                                Chọn ảnh
                            </Button>
                            <input type="file" ref={iconRef} onChange={(e) => handleSelectImage('icon_url', e)} hidden />
                            <div className="mt-2 ml-5">
                                <img src={iconUrl} alt={name} style={{ border: '1px solid #ececec', maxWidth: 100, maxHeight: 70 }} />
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Đóng
                </Button>
                <Button variant="success" onClick={handleUpdateSoftCard}>
                    Cập nhật
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalChannel;
