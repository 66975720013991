import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPlusCircle, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import routes from '~/configs/routes';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import ModalDestroy from '~/components/ModalDestroy';
import CusPagination from '~/components/CusPagination';
import ModalAdvertise from '~/components/ModalAdvertise';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { convertCurrency, getDomainWithProtocol } from '~/configs';
import { requestDestroyAdvertise, requestGetAdvertises } from '~/services/advertise';

function Advertise() {
    const [pages, setPages] = useState(1);
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(null);
    const [destroy, setDestroy] = useState(false);
    const [advertise, setAdvertise] = useState(null);
    const [advertises, setAdvertises] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const page = searchParams.get('page');
    const hostname = getDomainWithProtocol(origin);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Quảng cáo - Quản trị website';

        dispatch(startLoading());
        const fetch = async () => {
            const result = await requestGetAdvertises(page || 1);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                setPages(result.pages);
                setAdvertises(result.data);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const handleCopyUrlAdvertise = async (query_id) => {
        await navigator.clipboard.writeText(`${hostname}?q=${query_id}`);

        alertSuccess('Đã sao chép vào keyboard');
    };

    // Show hàm xóa
    const handleShowModalDestroy = (advertise, index) => {
        setDestroy(true);
        setAdvertise(advertise);
        setIndex(index);
    };

    // Hàm xác nhận xóa
    const handleConfirmDestroyAdvertise = async () => {
        if (!advertise._id) {
            return alertError('Dữ liệu tài khoản cần xóa không tồn tại');
        }

        dispatch(startLoading());
        const result = await requestDestroyAdvertise(advertise._id);

        dispatch(stopLoading());
        setDestroy(false);
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(routes.login);
        } else if (result.status === 200) {
            alertSuccess(result.message);
            const clone = [...advertises];
            clone.splice(index, 1);
            setAdvertises(clone);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Quảng cáo" />

                    <Col md={7}>
                        <div className="float-right mt-5">
                            <Button variant="success" className="d-flex align-items-center" onClick={() => setShow(true)}>
                                <FontAwesomeIcon icon={faPlusCircle} />
                                <span className="ml-2">Thêm mới</span>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped dataTable">
                                        <thead>
                                            <tr>
                                                <th>Tên</th>
                                                <th>Truy cập</th>
                                                <th>Tổng nạp</th>
                                                <th>Thực</th>
                                                <th>Nhận</th>
                                                <th>Ngày tạo/cập nhật</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {advertises.length > 0 ? (
                                                advertises.map((advertise, index) => (
                                                    <tr key={advertise._id}>
                                                        <td>{advertise.nickname}</td>
                                                        <td>{advertise.hits_count}</td>
                                                        <td className="text-danger">{convertCurrency(advertise.declared_value)}</td>
                                                        <td className="text-success">{convertCurrency(advertise.value)}</td>
                                                        <td className="text-info">{convertCurrency(advertise.amount)}</td>

                                                        <td>
                                                            {moment(advertise.created_at).format('YYYY-MM-DD HH:mm:ss')}
                                                            <br />
                                                            {moment(advertise.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                                                        </td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                variant="success"
                                                                title="Sao chép đường dẫn"
                                                                onClick={() => handleCopyUrlAdvertise(advertise.query_id)}
                                                            >
                                                                <FontAwesomeIcon icon={faCopy} />
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                title="Xóa"
                                                                className="ml-2"
                                                                onClick={() => handleShowModalDestroy(advertise, index)}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={11}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>

                            {pages > 1 && (
                                <Card.Footer>
                                    <Row>
                                        <Col xl={12}>
                                            <div className="float-right">
                                                <CusPagination pages={pages} page={page} setSearchParams={setSearchParams} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>

            {show && <ModalAdvertise show={show} setShow={setShow} advertises={advertises} setAdvertises={setAdvertises} />}
            {destroy && (
                <ModalDestroy
                    show={destroy}
                    setShow={setDestroy}
                    name={`${advertise.nickname} sẽ xóa toàn bộ dữ liệu lượt truy cập`}
                    onClick={handleConfirmDestroyAdvertise}
                />
            )}
        </div>
    );
}

export default Advertise;
