import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Card, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faPen } from '@fortawesome/free-solid-svg-icons';

import routes from '~/configs/routes';
import { alertError } from '~/configs/alert';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestGetGames, requestUpdateGame } from '~/services/game';

function GameCategory() {
    const [games, setGames] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Danh sách trò chơi - Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestGetGames();

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                setGames(result.data);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Hàm thay đổi trạng thái sản phẩm
    const handleToggleStatus = async (id, index, type) => {
        if (!id) {
            alertError('ID sản phẩm không tồn tại');
        } else {
            dispatch(startLoading());
            const result = await requestUpdateGame({}, id, type);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                const clone = [...games];
                clone[index].status = !clone[index].status;
                setGames(clone);
            } else {
                alertError(result.error);
            }
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Trò chơi" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Tên game</th>
                                                <th>Mã game</th>
                                                <th>Ảnh</th>
                                                <th>Trạng thái</th>
                                                <th>Ngày tạo</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {games.length > 0 ? (
                                                games.map((game, index) => (
                                                    <tr key={game._id}>
                                                        <td>{game.priority}</td>
                                                        <td>{game.title}</td>
                                                        <td>{game.app_code}</td>

                                                        <td>
                                                            <img
                                                                src={game.avatar_url}
                                                                alt={game.title}
                                                                style={{ width: 70, height: 70, borderRadius: 14 }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <div
                                                                className={`switch round ${game.status ? 'on' : 'off'}`}
                                                                onClick={() => handleToggleStatus(game._id, index, 'status')}
                                                            >
                                                                <div className="toggle" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span>{moment(game.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                        </td>
                                                        <td>
                                                            <Link to={`${routes.games}/edit/${game._id}`}>
                                                                <Button size="sm" className="mr-2" variant="info" title="Sửa thông tin">
                                                                    <FontAwesomeIcon icon={faPen} />
                                                                </Button>
                                                            </Link>
                                                            <Link to={`${routes.games}/softcard/${game._id}`}>
                                                                <Button size="sm" className="mr-2" variant="success" title="Xem loại thẻ">
                                                                    <FontAwesomeIcon icon={faCreditCard} />
                                                                </Button>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={7}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default GameCategory;
