import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';

import routes from '~/configs/routes';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { requestCreatePromotion } from '~/services/promotion';
import { startLoading, stopLoading } from '~/redux/reducer/module';

function ModalPromotion({ show, setShow, callback, setCallback }) {
    const [code, setCode] = useState('');
    const [title, setTitle] = useState('');
    const [value, setValue] = useState(0);
    const [description, setDescription] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCreatePromotion = async () => {
        if (!title || !code) {
            return alertError('Vui lòng nhập đầy đủ thông tin');
        }
        if (value <= 0) {
            return alertError('Giá trị khuyến mãi phải lớn hơn 0');
        }
        if (value > 100) {
            return alertError('Giá trị khuyến mãi phải từ 100 trở xuống');
        }

        const data = {
            title,
            code,
            value,
            description,
        };

        dispatch(startLoading());
        const result = await requestCreatePromotion(data);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(routes.login);
        } else if (result.status === 200) {
            setShow(false);
            alertSuccess(result.message);
            setCallback([result.data, ...callback]);
        } else {
            alertError(result.error);
        }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
            <Modal.Header className="justify-content-center">
                <Modal.Title style={{ fontSize: '1.8rem' }}>Thêm mã khuyễn mãi</Modal.Title>
            </Modal.Header>
            <Modal.Body className="my-4">
                <Form.Group className="mb-3">
                    <Form.Label>Nhập tên hiển thị</Form.Label>
                    <Form.Control placeholder="Nhập tên hiển thị" value={title} onChange={(e) => setTitle(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Mã</Form.Label>
                    <Form.Control placeholder="Mã" value={code} onChange={(e) => setCode(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Khuyễn mãi (%)</Form.Label>
                    <Form.Control placeholder="Khuyễn mãi (%)" value={value} onChange={(e) => setValue(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Mô tả ngắn</Form.Label>
                    <Form.Control placeholder="Mô tả ngắn" value={description} onChange={(e) => setDescription(e.target.value)} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={() => setShow(false)}>
                    Đóng
                </Button>
                <Button size="sm" variant="success" onClick={handleCreatePromotion}>
                    Thêm mới
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalPromotion;
