import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertCurrency } from '~/configs';
import PageTitle from '~/components/PageTitle';
import ModalDestroy from '~/components/ModalDestroy';
import { logoutSuccess } from '~/redux/reducer/auth';
import CusPagination from '~/components/CusPagination';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestDestroyUser, requestGetUsers, requestUpdateUser } from '~/services/user';
import routes from '~/configs/routes';

function Users() {
    const [pages, setPages] = useState(1);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [show, setShow] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const page = searchParams.get('page');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Danh sách người dùng -  Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());

            const result = await requestGetUsers(page || 1, null);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                setUsers(result.data);
                setPages(result.pages);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    // Hàm thay đổi trạng thái người dùng
    const handleToggle = async (id, index) => {
        if (!id) {
            alertError('ID người dùng không tồn tại');
        } else {
            dispatch(startLoading());
            const result = await requestUpdateUser(id);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                const clone = [...users];
                clone[index].status = !clone[index].status;
                setUsers(clone);
            } else {
                alertError(result.error);
            }
        }
    };

    // Hàm xóa người dùng
    const handleDestroy = async () => {
        if (!user) {
            return alertError('Người dùng không tồn tại');
        }

        dispatch(startLoading());
        const result = await requestDestroyUser(user._id);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(routes.login);
        } else if (result.status === 200) {
            const clone = users.filter((item) => item._id !== user._id);
            setUsers(clone);
            setShow(false);
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Người dùng" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped dataTable">
                                        <thead>
                                            <tr>
                                                <th>Thông tin</th>
                                                <th>Loại tài khoản</th>
                                                <th>Tổng nạp</th>
                                                <th>Thực</th>
                                                <th>Nhận</th>
                                                <th>Trạng thái</th>
                                                <th>Ngày tạo</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.length > 0 ? (
                                                users.map((user, index) => (
                                                    <tr key={user._id}>
                                                        <td>
                                                            <span>{user.info.id}</span>
                                                            <br />
                                                            <span>{user.info.name || 'SAI'}</span>
                                                        </td>
                                                        <td>{user.user_type.toUpperCase()}</td>
                                                        <td className="text-danger">{convertCurrency(user.declared_value)}</td>
                                                        <td className="text-success">{convertCurrency(user.value)}</td>
                                                        <td className="text-info">{convertCurrency(user.amount)}</td>
                                                        <td>
                                                            <div
                                                                className={`switch round ${user.status ? 'on' : 'off'}`}
                                                                onClick={() => handleToggle(user._id, index)}
                                                            >
                                                                <div className="toggle" />
                                                            </div>
                                                        </td>
                                                        <td>{moment(user.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                title="Xóa"
                                                                onClick={() => {
                                                                    setUser(user);
                                                                    setShow(true);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={10}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>

                            {pages > 1 && (
                                <Card.Footer>
                                    <Row>
                                        <Col xl={12}>
                                            <div className="float-right">
                                                <CusPagination pages={pages} page={page} setSearchParams={setSearchParams} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>

            {show && <ModalDestroy show={show} setShow={setShow} name={user.info.name} onClick={handleDestroy} />}
        </div>
    );
}

export default Users;
