import Home from '~/layouts/Home';
import routes from '~/configs/routes';
import Destroy from '~/pages/Destroy';
import Setting from '~/pages/Setting';
import NotFound from '~/layouts/NotFound';
import Users from '~/pages/account/Users';
import Admin from '~/pages/account/Admin';
import Advertise from '~/pages/Advertise';
import Actions from '~/pages/history/Actions';
import Partners from '~/pages/module/Partners';
import Login from '~/layouts/components/Login';
import HistoryUser from '~/pages/history/User';
import StatDaily from '~/pages/statistic/Daily';
import Request from '~/pages/Advertise/Request';
import Notification from '~/pages/Notification';
import GameCategory from '~/pages/GameCategory';
import HistoryAdmin from '~/pages/history/Admin';
import Promotion from '~/pages/module/Promotion';
import Callback from '~/pages/statistic/Callback';
import SoftCard from '~/pages/GameCategory/SoftCard';
import StatCharging from '~/pages/statistic/Charging';
import HistoryCallBack from '~/pages/history/Callback';
import UpdateGame from '~/pages/GameCategory/UpdateGame';
import ForgotPassword from '~/components/ForgotPassword';
import UpdatePartner from '~/pages/module/Partners/Update';

const privateRoutes = [
    { path: routes.home, component: Home },
    { path: routes.users, component: Users },
    { path: routes.admin, component: Admin },
    { path: routes.tools, component: Destroy },
    { path: routes.request, component: Request },
    { path: routes.settings, component: Setting },
    { path: routes.partners, component: Partners },
    { path: routes.games, component: GameCategory },
    { path: '*', component: NotFound, layout: null },
    { path: routes.promotion, component: Promotion },
    { path: routes.advertises, component: Advertise },
    { path: routes.login, component: Login, layout: null },
    { path: routes.notifications, component: Notification },
    { path: routes.games + '/edit/:id', component: UpdateGame },
    { path: routes.partners + '/edit', component: UpdatePartner },
    { path: routes.history + routes.actions, component: Actions },
    { path: routes.history + routes.users, component: HistoryUser },
    { path: routes.forgot, component: ForgotPassword, layout: null },
    { path: routes.history + routes.admin, component: HistoryAdmin },
    { path: routes.statistic + routes.dailystat, component: StatDaily },
    { path: routes.games + routes.softcard + '/:id', component: SoftCard },
    { path: routes.statistic + routes.chargings, component: StatCharging },
    { path: routes.history + routes.callback, component: HistoryCallBack },
    { path: routes.statistic + routes.chargings + routes.callback, component: Callback },
];

export { privateRoutes };
