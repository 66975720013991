import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import routes from '~/configs/routes';
import { convertCurrency } from '~/configs';
import PageTitle from '~/components/PageTitle';
import ModalDestroy from '~/components/ModalDestroy';
import { logoutSuccess } from '~/redux/reducer/auth';
import ModalPromotion from '~/components/ModalPromotion';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestDestroyPromotion, requestGetPromotions } from '~/services/promotion';

function Promotion() {
    const [promotion, setPromotion] = useState(null);
    const [promotions, setPromotions] = useState([]);
    const [openCreate, setOpenCreate] = useState(false);
    const [openDestroy, setOpenDestroy] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Danh sách mã khuyễn mãi - Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestGetPromotions();

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                setPromotions(result.data);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleShowDelete = async (promotion) => {
        setOpenDestroy(true);
        setPromotion(promotion);
    };

    // Hàm xác nhận xóa sản phẩm
    const handleConfirmDestroy = async () => {
        if (!promotion._id) {
            alertError('ID mã khuyễn mãi không tồn tại');
        } else {
            dispatch(startLoading());
            const result = await requestDestroyPromotion(promotion._id);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                const clonePromotions = [...promotions];
                const promotionIndex = clonePromotions.findIndex((item) => item._id === promotion._id);
                clonePromotions.splice(promotionIndex, 1);

                setOpenDestroy(false);
                setPromotions(clonePromotions);
                alertSuccess(result.message);
            } else {
                alertError(result.error);
            }
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Mã khuyễn mãi" />

                    <Col xl={7}>
                        <div className="float-right">
                            <Button variant="success" className="mt-xl-5 mt-3 mt-md-0 box-center" onClick={() => setOpenCreate(true)}>
                                <FontAwesomeIcon icon={faPlus} />
                                <span> Thêm mới</span>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Tên hiển thị</th>
                                                <th>Mã</th>
                                                <th>Khuyễn mãi</th>
                                                <th>Tổng nạp</th>
                                                <th>Thực</th>
                                                <th>Nhận</th>
                                                <th>Ngày tao</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {promotions.length > 0 ? (
                                                promotions.map((promotion) => (
                                                    <tr key={promotion._id}>
                                                        <td>{promotion.title}</td>
                                                        <td>{promotion.code}</td>
                                                        <td>{promotion.value}%</td>
                                                        <td className="text-danger">{convertCurrency(promotion.declared_value)}</td>
                                                        <td className="text-success">{convertCurrency(promotion.value)}</td>
                                                        <td className="text-info">{convertCurrency(promotion.amount)}</td>
                                                        <td>{moment(promotion.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                title="Tắt"
                                                                onClick={() => handleShowDelete(promotion)}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={8}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            {openCreate && <ModalPromotion show={openCreate} setShow={setOpenCreate} callback={promotions} setCallback={setPromotions} />}
            {openDestroy && promotion && (
                <ModalDestroy show={openDestroy} setShow={setOpenDestroy} name={promotion.title} onClick={handleConfirmDestroy} />
            )}
        </div>
    );
}

export default Promotion;
