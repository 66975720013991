import routes from './routes';
import {
    faGauge,
    faUsers,
    faCubes,
    faGears,
    faWrench,
    faHeadset,
    faGamepad,
    faChartColumn,
    faClockRotateLeft,
} from '@fortawesome/free-solid-svg-icons';

const sidebar = [
    {
        title: 'Bảng quản trị',
        path: routes.home,
        icon: faGauge,
        sub: null,
    },
    {
        title: 'Trò chơi',
        path: null,
        icon: faGamepad,
        sub: [
            {
                title: 'Danh sách',
                path: routes.games,
            },
        ],
    },
    {
        title: 'Thống kê',
        path: null,
        icon: faChartColumn,
        sub: [
            {
                title: 'Đổi thẻ',
                path: routes.statistic + routes.chargings,
            },
            {
                title: 'Tổng hợp ngày',
                path: routes.statistic + routes.dailystat,
            },
        ],
    },
    {
        title: 'Tài khoản',
        path: null,
        icon: faUsers,
        sub: [
            {
                title: 'Quản trị viên',
                path: routes.admin,
            },
            {
                title: 'Người dùng',
                path: routes.users,
            },
        ],
    },
    {
        title: 'Lịch sử',
        path: null,
        icon: faClockRotateLeft,
        sub: [
            {
                title: 'Quản trị viên',
                path: routes.history + routes.admin,
            },
            {
                title: 'Người dùng',
                path: routes.history + routes.users,
            },
            {
                title: 'Lịch sử callback',
                path: routes.history + routes.callback,
            },
            {
                title: 'Hoạt động quản trị',
                path: routes.history + routes.actions,
            },
        ],
    },
    {
        title: 'Công cụ',
        path: null,
        icon: faWrench,
        sub: [
            {
                title: 'Xóa dữ liệu',
                path: routes.tools,
            },
        ],
    },
    {
        title: 'Quảng cáo',
        path: null,
        icon: faHeadset,
        sub: [
            {
                title: 'Danh sách',
                path: routes.advertises,
            },
            {
                title: 'Lượt truy cập',
                path: routes.request,
            },
        ],
    },
    {
        title: 'Mô-đun khác',
        path: null,
        icon: faCubes,
        sub: [
            {
                title: 'Khuyễn mãi',
                path: routes.promotion,
            },
            {
                title: 'Đối tác kết nối',
                path: routes.partners,
            },
        ],
    },
    {
        title: 'Cấu hình hệ thống',
        path: null,
        icon: faGears,
        sub: [
            {
                title: 'Cài đặt',
                path: routes.settings,
            },
        ],
    },
];

export default sidebar;
