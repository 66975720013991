import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons';

import config from '~/configs';
import { alertError, alertSuccess } from '~/configs/alert';
import PageTitle from '~/components/PageTitle';
import { requestGetGames, requestUpdateGame } from '~/services/game';
import { logoutSuccess } from '~/redux/reducer/auth';
import { requestUploadImage } from '~/services/upload';
import { startLoading, stopLoading } from '~/redux/reducer/module';

const { games, login } = config.routes;

function UpdateGame() {
    const [title, setTitle] = useState('');
    const [appCode, setAppCode] = useState('');
    const [coverUrl, setCoverUrl] = useState('');
    const [avatarUrl, setAvatarUrl] = useState('');
    const [bonusIcon, setBonusIcon] = useState('');
    const [description, setDescription] = useState('');

    const { id } = useParams();

    const coverRef = useRef();
    const bonusRef = useRef();
    const avatarRef = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            const fetch = async () => {
                dispatch(startLoading());
                const result = await requestGetGames(id);

                dispatch(stopLoading());
                if (result.status === 401 || result.status === 403) {
                    dispatch(logoutSuccess());
                    navigate(login);
                } else if (result.status === 200) {
                    const { title, app_code, avatar_url, cover_url, bonus_icon, description } = result.data;

                    setTitle(title);
                    setAppCode(app_code);
                    setAvatarUrl(avatar_url);
                    setCoverUrl(cover_url);
                    setBonusIcon(bonus_icon);
                    setDescription(description);
                    document.title = `Cập nhật thông tin ${title} - Quản trị website`;
                } else {
                    navigate(games);
                    alertError(result.error);
                }
            };
            fetch();
        } else {
            navigate(games);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleSelectImage = async (type, e) => {
        dispatch(startLoading());
        const formData = new FormData();
        formData.append('image', e.target.files[0]);

        const result = await requestUploadImage(formData);

        dispatch(stopLoading());
        if (result.status === 200) {
            if (type === 'avatar_url') {
                setAvatarUrl(result.data);
            }
            if (type === 'cover_url') {
                setCoverUrl(result.data);
            }
            if (type === 'bonus_icon') {
                setBonusIcon(result.data);
            }
        } else {
            alertError(result.error);
        }
    };

    const handleUpdateTelco = async () => {
        if (!id) {
            return alertError('ID trò chơi không tồn tại');
        }
        if (!title) {
            return alertError('Tên trò chơi không được để trống');
        }
        if (!appCode) {
            return alertError('Mã app không được để trống');
        }
        if (!avatarUrl) {
            return alertError('Ảnh đại diện trò chơi không được để trống');
        }
        if (!coverUrl) {
            return alertError('Ảnh bìa trò chơi không được để trống');
        }
        if (!bonusIcon) {
            return alertError('Ảnh bonus trò chơi không được để trống');
        }

        const data = {
            title,
            app_code: appCode,
            avatar_url: avatarUrl,
            cover_url: coverUrl,
            bonus_icon: bonusIcon,
            description,
        };

        dispatch(startLoading());
        const result = await requestUpdateGame(data, id, 'info');

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Cập nhật thông tin" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>Cập nhật thông tin</Card.Title>
                                <Link to={games}>
                                    <Button variant="warning">
                                        <FontAwesomeIcon icon={faHouseChimney} />
                                        <span>Trang chính</span>
                                    </Button>
                                </Link>
                            </Card.Header>
                            <Card.Body>
                                <Col xl={6} className="p-0 mt-3">
                                    <div className="form-group col-md-12">
                                        <label>Tên game:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Tên game"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Mã game:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ví dụ: free-fire"
                                            value={appCode}
                                            onChange={(e) => setAppCode(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="d-block">Ảnh game:</label>
                                        <Button variant="info" className="mr-5" onClick={() => avatarRef.current.click()}>
                                            Chọn ảnh
                                        </Button>
                                        <input type="file" ref={avatarRef} onChange={(e) => handleSelectImage('avatar_url', e)} hidden />
                                        <img
                                            className="mt-2"
                                            src={avatarUrl}
                                            alt={title}
                                            style={{
                                                border: '1px solid #ececec',
                                                width: 70,
                                                height: 70,
                                                borderRadius: 14,
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="d-block">Ảnh nền:</label>
                                        <Button variant="info" className="mr-5" onClick={() => coverRef.current.click()}>
                                            Chọn ảnh
                                        </Button>
                                        <input type="file" ref={coverRef} onChange={(e) => handleSelectImage('cover_url', e)} hidden />
                                        <img
                                            className="mt-2"
                                            src={coverUrl}
                                            alt={title}
                                            style={{ border: '1px solid #ececec', width: 800, height: 120 }}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="d-block">Ảnh nạp:</label>
                                        <Button variant="info" className="mr-5" onClick={() => bonusRef.current.click()}>
                                            Chọn ảnh
                                        </Button>
                                        <input type="file" ref={bonusRef} onChange={(e) => handleSelectImage('bonus_icon', e)} hidden />
                                        <img
                                            className="mt-2"
                                            src={bonusIcon}
                                            alt={title}
                                            style={{
                                                border: '1px solid #ececec',
                                                width: 30,
                                                height: 30,
                                                borderRadius: 14,
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Mô tả:</label>
                                        <textarea
                                            className="form-control"
                                            rows={3}
                                            placeholder="Có thể bỏ qua nếu không có"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Card.Body>
                            <Card.Footer>
                                <Button onClick={handleUpdateTelco}>Cập nhật</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default UpdateGame;
