import request from '~/utils';

export const requestGetChargings = async ({ page, telco, amount, partner, date_start, date_end, status, code, serial, type }) => {
    try {
        const res = await request.get('/chargings', {
            params: {
                page,
                telco,
                amount,
                partner,
                date_start,
                date_end,
                status,
                code,
                serial,
                type,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetTotalCharging = async (type) => {
    try {
        const res = await request.get('/chargings/total', {
            params: {
                type,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetDailyChargings = async () => {
    try {
        const res = await request.get('/chargings/daily', {
            params: {
                _v: Math.random(),
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestDestroyCharging = async (type, id) => {
    try {
        const res = await request.delete('/chargings/destroy', {
            params: {
                type,
                status: type ? '101' : null,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUpdateCharging = async (type, id, data) => {
    try {
        const res = await request.put('/chargings/update', data, {
            params: {
                type,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestCallbackAloneCharging = async (id) => {
    try {
        const res = await request.get('/chargings/callback', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestCallbackAllChargings = async () => {
    try {
        const res = await request.get('/chargings/callback-all');

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
