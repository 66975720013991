import routes from './routes';
import sidebar from './sidebar';

const config = {
    routes,
    sidebar,
};

export const convertCurrency = (number) => {
    let check = typeof number === 'number' ? true : false;

    return check ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'đ' : 'Null';
};

export const getDomainWithProtocol = (url) => {
    const parsedUrl = new URL(url);
    const hostname = parsedUrl.hostname;
    const protocol = parsedUrl.protocol;

    // Phân tích hostname để lấy phần tên miền chính
    const parts = hostname.split('.');
    // Chỉ lấy 2 phần cuối của hostname để bỏ qua các subdomain
    const mainDomain = parts.length > 2 ? parts.slice(-2).join('.') : hostname;

    // Trả về tên miền kèm theo protocol
    return `${protocol}//${mainDomain}`;
};

export default config;
