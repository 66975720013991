import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { faGears } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row, Tab, Tabs, Table, Button } from 'react-bootstrap';

import config from '~/configs';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import { requestUploadImage } from '~/services/upload';
import { alertError, alertSuccess } from '~/configs/alert';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestGetSettings, requestUpdateSetting } from '~/services/settings';

function Setting() {
    const [bannerUrl, setBannerUrl] = useState('');
    const [websiteStatus, setWebsiteStatus] = useState(true);
    const [chatbotStatus, setChatbotStatus] = useState(false);
    const [promotionActive, setPromotionActive] = useState(false);
    const [apikeyGarena, setApiKeyGarena] = useState({ category: '', apikey: '', status: false, use: 0, expired_at: '' });
    const [apikeyFreefire, setApiKeyFreefire] = useState({ category: '', apikey: '', status: false, use: 0, expired_at: '' });

    const [active, setActive] = useState(false);

    const [rate, setRate] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [partnerID, setPartnerID] = useState('');
    const [partnerKey, setPartnerKey] = useState('');
    const [partnerUrl, setPartnerUrl] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inputRef = useRef();

    useEffect(() => {
        document.title = 'Cài đạt website - Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestGetSettings();

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(config.routes.login);
            } else if (result.status === 200) {
                const { apikey_freefire, apikey_garena, banner_url, promotion_active, website_status, chatbot_status, chargings } =
                    result.data;

                setBannerUrl(banner_url);
                setApiKeyGarena(apikey_garena);
                setWebsiteStatus(website_status);
                setApiKeyFreefire(apikey_freefire);
                setPromotionActive(promotion_active);

                if (chargings) {
                    setActive(true);
                    setChatbotStatus(chatbot_status);

                    setRate(chargings.rate);
                    setPartnerID(chargings.partner_id);
                    setPartnerKey(chargings.partner_key);
                    setPartnerUrl(chargings.partner_url);
                }
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeApikeyAccount = useCallback((e) => {
        setApiKeyFreefire((prevState) => ({
            ...prevState,
            apikey: e.target.value,
        }));
    }, []);

    const handleChangeApikeyGarena = useCallback((e) => {
        setApiKeyGarena((prevState) => ({
            ...prevState,
            apikey: e.target.value,
        }));
    }, []);

    const handleUpdateSetting = async (type) => {
        dispatch(startLoading());

        let data = null;
        if (type === 'apikey_freefire') {
            data = {
                apikey_freefire: apikeyFreefire.apikey,
            };
        }
        if (type === 'apikey_garena') {
            data = {
                apikey_garena: apikeyGarena.apikey,
            };
        }
        if (type === 'info') {
            data = {
                banner_url: bannerUrl,
                website_status: websiteStatus,
                chatbot_status: chatbotStatus,
                promotion_active: promotionActive,
            };
        }
        if (type === 'chargings') {
            data = {
                rate: rate,
                partner_id: partnerID,
                partner_key: partnerKey,
                partner_url: partnerUrl,
            };
        }

        if (!data) {
            return alertError('Vui lòng chọn cấu hình cần lưu');
        }

        const result = await requestUpdateSetting(data, type);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(config.routes.login);
        } else if (result.status === 200) {
            if (type === 'apikey_freefire') {
                setApiKeyFreefire(result.data);
            }
            if (type === 'apikey_garena') {
                setApiKeyGarena(result.data);
            }

            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    const handleSelectImage = async (e) => {
        dispatch(startLoading());

        const formData = new FormData();
        formData.append('image', e.target.files[0]);

        const result = await requestUploadImage(formData);

        dispatch(stopLoading());
        if (result.status === 200) {
            setImageUrl(result.data);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Cấu hình chung" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card style={{ borderTop: '3px solid #007bff' }}>
                            <Card.Header>
                                <Card.Title>
                                    <FontAwesomeIcon icon={faGears} />
                                    <span className="ml-3">Danh sách cấu hình</span>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body style={{ padding: 20 }}>
                                <Tabs defaultActiveKey="info">
                                    <Tab eventKey="info" title="Thông tin">
                                        <Row className="mt-5 mb-4">
                                            <Col className="text-center">
                                                <div className="form-group">
                                                    <Button onClick={() => alertError('Chức năng đang được phát triển')}>Chọn ảnh</Button>
                                                </div>
                                                <label>Favicon Icon</label>
                                            </Col>
                                            <Col className="text-center">
                                                <div className="form-group">
                                                    <Button onClick={() => alertError('Chức năng đang được phát triển')}>Chọn ảnh</Button>
                                                </div>
                                                <label>Website Logo</label>
                                            </Col>
                                            <Col className="text-center">
                                                <div className="form-group">
                                                    <Button onClick={() => alertError('Chức năng đang được phát triển')}>Chọn ảnh</Button>
                                                </div>
                                                <label>Backend Logo</label>
                                            </Col>
                                        </Row>
                                        <Row className="mt-xl-5">
                                            <Col xl={6}>
                                                <Table bordered>
                                                    <thead>
                                                        <tr>
                                                            <th>FREE FIRE</th>
                                                            <th>{apikeyFreefire.category}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Key</td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={apikeyFreefire.apikey}
                                                                    onChange={handleChangeApikeyAccount}
                                                                    placeholder="Lấy apikey tại https://thegioicode.com"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Trạng thái</td>
                                                            <td>
                                                                <select className="form-control" value={apikeyFreefire.status} disabled>
                                                                    <option value={true}>Hoạt động</option>
                                                                    <option value={false}>Không tồn tại</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Lượt dùng</td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={apikeyFreefire.use}
                                                                    disabled
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Hết hạn</td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={apikeyFreefire.expired_at}
                                                                    disabled
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>

                                                <div className="mt-4 float-right">
                                                    <Button onClick={() => handleUpdateSetting('apikey_freefire')}>Cập nhật</Button>
                                                </div>
                                            </Col>
                                            <Col xl={6} className="mb-5">
                                                <Table bordered>
                                                    <thead>
                                                        <tr>
                                                            <th>GARENA</th>
                                                            <th>{apikeyGarena.category}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Key</td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={apikeyGarena.apikey}
                                                                    onChange={handleChangeApikeyGarena}
                                                                    placeholder="Lấy apikey tại https://thegioicode.com"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Trạng thái</td>
                                                            <td>
                                                                <select className="form-control" value={apikeyGarena.status} disabled>
                                                                    <option value={true}>Hoạt động</option>
                                                                    <option value={false}>Không tồn tại</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Lượt dùng</td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={apikeyGarena.use}
                                                                    disabled
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Hết hạn</td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={apikeyGarena.expired_at}
                                                                    disabled
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>

                                                <div className="mt-4 float-right">
                                                    <Button onClick={() => handleUpdateSetting('apikey_garena')}>Cập nhật</Button>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <Table bordered>
                                                    <tbody>
                                                        {active && (
                                                            <tr>
                                                                <td>Chatbot</td>
                                                                <td>
                                                                    <select
                                                                        className="form-control"
                                                                        value={chatbotStatus}
                                                                        onChange={(e) => setChatbotStatus(e.target.value)}
                                                                    >
                                                                        <option value={true}>ONLINE</option>
                                                                        <option value={false}>OFFLINE</option>
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td>Website</td>
                                                            <td>
                                                                <select
                                                                    className="form-control"
                                                                    value={websiteStatus}
                                                                    onChange={(e) => setWebsiteStatus(e.target.value)}
                                                                >
                                                                    <option value={true}>ONLINE</option>
                                                                    <option value={false}>OFFLINE</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Khuyễn mãi</td>
                                                            <td>
                                                                <select
                                                                    className="form-control"
                                                                    value={promotionActive}
                                                                    onChange={(e) => setPromotionActive(e.target.value)}
                                                                >
                                                                    <option value={true}>ONLINE</option>
                                                                    <option value={false}>OFFLINE</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Link ảnh banner</td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={bannerUrl}
                                                                    onChange={(e) => setBannerUrl(e.target.value)}
                                                                    placeholder="Link ảnh banner"
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <Row className="my-5">
                                            <Col xl={12}>
                                                <Button onClick={() => handleUpdateSetting('info')}>Cập nhật</Button>
                                            </Col>
                                        </Row>
                                    </Tab>

                                    {active && (
                                        <Tab eventKey="chargings" title="Đổi thẻ cào">
                                            <Row className="mt-5">
                                                <Col xl={6} className="p-0">
                                                    <Table bordered>
                                                        <tbody>
                                                            <tr>
                                                                <td>Tỉ lệ</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={rate}
                                                                        onChange={(e) => setRate(e.target.value)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Partner ID</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={partnerID}
                                                                        onChange={(e) => setPartnerID(e.target.value)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Partner Key</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={partnerKey}
                                                                        onChange={(e) => setPartnerKey(e.target.value)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Partner URL</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={partnerUrl}
                                                                        onChange={(e) => setPartnerUrl(e.target.value)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <Button
                                                                        variant="info"
                                                                        className="mr-5"
                                                                        onClick={() => inputRef.current.click()}
                                                                    >
                                                                        Chọn ảnh
                                                                    </Button>
                                                                    <input
                                                                        type="file"
                                                                        ref={inputRef}
                                                                        onChange={(e) => handleSelectImage(e)}
                                                                        hidden
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={imageUrl}
                                                                        onChange={(e) => setImageUrl(e.target.value)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                            <Row className="my-5">
                                                <Col xl={12}>
                                                    <Button onClick={() => handleUpdateSetting('chargings')}>Cập nhật</Button>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    )}
                                </Tabs>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Setting;
