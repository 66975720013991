function Footer() {
    const year = new Date().getFullYear();

    return (
        <div className="wrapper-footer">
            <strong>
                <span>
                    <a href="https://thegioicode.com" style={{ marginRight: 3 }} target="_blank" rel="noreferrer">
                        Thegioicode.com
                    </a>
                    © 2019 - {year}.
                </span>
            </strong>
            <span> All rights reserved</span>
            <div className="float-right">
                <strong>Version 2.1</strong>
            </div>
        </div>
    );
}

export default Footer;
