import request from '~/utils';

export const requestGetPromotions = async (id = null, type = null) => {
    try {
        const res = await request.get('/promotions', {
            params: {
                _v: id ? null : Math.random(),
                type,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestCreatePromotion = async (data) => {
    try {
        const res = await request.post('/promotions/create', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestDestroyPromotion = async (id) => {
    try {
        const res = await request.delete('/promotions/destroy', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
