import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';

import routes from '~/configs/routes';
import { logoutSuccess } from '~/redux/reducer/auth';
import { alertError, alertSuccess } from '~/configs/alert';
import { requestCreateAdvertise } from '~/services/advertise';
import { startLoading, stopLoading } from '~/redux/reducer/module';

function ModalAdvertise({ show, setShow, advertises, setAdvertises }) {
    const [queryId, setQueryId] = useState('');
    const [nickName, setNickName] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCreateSEO = async () => {
        if (!nickName || !queryId) {
            return alertError('Vui lòng nhập đầy đủ thông tin');
        }

        const data = {
            nickname: nickName,
            query_id: queryId,
        };

        dispatch(startLoading());
        const result = await requestCreateAdvertise(data);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(routes.login);
        } else if (result.status === 200) {
            setShow(false);
            alertSuccess(result.message);
            setAdvertises([result.data, ...advertises]);
        } else {
            alertError(result.error);
        }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false}>
            <Modal.Header className="justify-content-center">
                <Modal.Title style={{ fontSize: '1.8rem' }}>Thêm đường dẫn</Modal.Title>
            </Modal.Header>
            <Modal.Body className="my-4">
                <Form.Group className="mb-3">
                    <Form.Label>Nhập tên</Form.Label>
                    <Form.Control placeholder="Nhập tên" value={nickName} onChange={(e) => setNickName(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Nhập mã</Form.Label>
                    <Form.Control placeholder="Nhập mã" value={queryId} onChange={(e) => setQueryId(e.target.value)} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={() => setShow(false)}>
                    Đóng
                </Button>
                <Button size="sm" variant="success" onClick={handleCreateSEO}>
                    Thêm mới
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalAdvertise;
