import request from '~/utils';

export const requestGetGames = async (id = null, type = null) => {
    try {
        const res = await request.get('/games', {
            params: {
                type,
                id,
                _v: id ? null : Math.random(),
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUpdateGame = async (data, id, type) => {
    try {
        const res = await request.put('/games/update', data, {
            params: {
                type,
                id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
