import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodePullRequest } from '@fortawesome/free-solid-svg-icons';

import config from '~/configs';
import { alertError } from '~/configs/alert';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import CusPagination from '~/components/CusPagination';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { requestActionRequest, requestGetRequests } from '~/services/request';

const { login } = config.routes;

const color = ['primary', 'info'];

function Request() {
    const [pages, setPages] = useState([]);
    const [totals, setTotals] = useState([]);
    const [requests, setRequests] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const page = searchParams.get('page');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Lượt truy cập website - Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());
            const result = await requestGetRequests({ page: page || 1 });

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(login);
            } else if (result.status === 200) {
                const data = result.total.map((item, index) => ({
                    title: item.title,
                    value: item.value,
                    color: color[index % color.length] || '',
                }));
                setTotals(data);
                setRequests(result.data);
                setPages(result.pages);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const handleActionRequest = async (request, index) => {
        if (!request._id) {
            return alertError('Dữ liệu truy cập không tồn tại');
        }

        dispatch(startLoading());
        const type = request.status ? 'block' : 'unlock';

        const result = await requestActionRequest(type, request._id);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(login);
        } else if (result.status === 200) {
            const clone = [...requests];
            clone[index].status = !clone[index].status;
            setRequests(clone);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Lượt truy cập website" />
                </Row>
            </div>
            <div className="content">
                <Row>
                    {totals.map((total, index) => (
                        <Col xl={3} key={index}>
                            <div className="home-box">
                                <span className={`home-icon bg-${total.color}`}>
                                    <FontAwesomeIcon icon={faCodePullRequest} />
                                </span>
                                <div className="home-content">
                                    <span className="home-text">{total.title}</span>
                                    <span className="home-number">{total.value}</span>
                                </div>
                            </div>
                        </Col>
                    ))}
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped dataTable">
                                        <thead>
                                            <tr>
                                                <th>Từ</th>
                                                <th>Loại</th>
                                                <th>Tên</th>
                                                <th>Điều hành</th>
                                                <th>IP</th>
                                                <th>Địa chỉ</th>
                                                <th>Thời gian</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {requests.length > 0 ? (
                                                requests.map((request, index) => (
                                                    <tr key={request._id}>
                                                        <td>
                                                            {request.advertise_id ? (
                                                                <Fragment>
                                                                    <span>{request.advertise_id.nickname}</span>
                                                                    <br />
                                                                    <span>{request.advertise_id.seo_url}</span>
                                                                </Fragment>
                                                            ) : (
                                                                <Fragment>{request.url}</Fragment>
                                                            )}
                                                        </td>
                                                        <td>{request.device.type}</td>
                                                        <td>{request.device.model}</td>
                                                        <td>{request.os?.name}</td>
                                                        <td>{request.ip}</td>
                                                        <td>{request.address || 'Trống'}</td>
                                                        <td>{moment(request.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                variant={request.status ? 'danger' : 'success'}
                                                                title="Chặn truy cập này"
                                                                onClick={() => handleActionRequest(request, index)}
                                                            >
                                                                {request.status ? 'Chặn' : 'Bỏ chặn'}
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={11}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>

                            {pages > 1 && (
                                <Card.Footer>
                                    <Row>
                                        <Col xl={12}>
                                            <div className="float-right">
                                                <CusPagination page={page} pages={pages} setSearchParams={setSearchParams} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Request;
