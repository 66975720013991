import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Row, Card, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney, faPen } from '@fortawesome/free-solid-svg-icons';

import { alertError } from '~/configs/alert';
import PageTitle from '~/components/PageTitle';
import { requestGetGames } from '~/services/game';
import ModalChannel from '~/components/ModalChannel';
import { logoutSuccess } from '~/redux/reducer/auth';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import routes from '~/configs/routes';

function SoftCard() {
    const [game, setGame] = useState('');
    const [show, setShow] = useState(false);
    const [channel, setChannel] = useState(null);
    const [channels, setChannels] = useState([]);

    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            const fetch = async () => {
                dispatch(startLoading());
                const result = await requestGetGames(id, 'softcard');
                dispatch(stopLoading());

                if (result.status === 401 || result.status === 403) {
                    dispatch(logoutSuccess());
                    navigate(routes.login);
                } else if (result.status === 200) {
                    setGame(result.data.title);
                    setChannels(result.data.channels);
                    document.title = `Danh sách thẻ trò chơi ${result.data.title} - Quản trị website`;
                } else {
                    navigate(routes.games);
                    alertError(result.error);
                }
            };
            fetch();
        } else {
            navigate(routes.games);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name={game} />
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xl={12}>
                        <Card>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <Card.Title>{game}</Card.Title>
                                <Link to={routes.games}>
                                    <Button variant="warning">
                                        <FontAwesomeIcon icon={faHouseChimney} />
                                        <span>Trang chính</span>
                                    </Button>
                                </Link>
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Tên thẻ</th>
                                                <th>Mã code</th>
                                                <th>Ảnh</th>
                                                <th>Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {channels.length > 0 ? (
                                                channels.map((channel, index) => (
                                                    <tr key={index}>
                                                        <td>{channel.priority}</td>
                                                        <td>{channel.name}</td>
                                                        <td>{channel.code}</td>
                                                        <td>
                                                            <img
                                                                src={channel.image_url}
                                                                alt={channel.name}
                                                                style={{ width: 100, height: 70 }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                className="mr-2"
                                                                variant="info"
                                                                title="Sửa"
                                                                onClick={() => {
                                                                    setShow(true);
                                                                    setChannel(channel);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faPen} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={5}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            {show && channel && <ModalChannel show={show} setShow={setShow} channel={channel} />}
        </div>
    );
}

export default SoftCard;
