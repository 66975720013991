const advertises = '/advertises';

const routes = {
    home: '/',
    users: '/users',
    games: '/games',
    admin: '/admin',
    tools: '/tools',
    login: '/login',
    create: '/create',
    actions: '/actions',
    history: '/histories',
    softcard: '/softcard',
    callback: '/callback',
    partners: '/partners',
    settings: '/settings',
    advertises: advertises,
    promotion: '/promotion',
    statistic: '/statistic',
    chargings: '/chargings',
    dailystat: '/dailystat',
    forgot: '/forgot-password',
    notifications: '/notifications',
    request: advertises + '/request',
};

export default routes;
