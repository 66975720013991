import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Badge, Button, Card, Col, Row, Table } from 'react-bootstrap';
import { faCheckCircle, faDownload, faGears, faMagnifyingGlass, faRotate, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import routes from '~/configs/routes';
import { convertCurrency } from '~/configs';
import PageTitle from '~/components/PageTitle';
import { logoutSuccess } from '~/redux/reducer/auth';
import ModalDestroy from '~/components/ModalAdvertise';
import ModalCharging from '~/components/ModalCharging';
import CusPagination from '~/components/CusPagination';
import { startLoading, stopLoading } from '~/redux/reducer/module';
import { alertError, alertSelected, alertSuccess } from '~/configs/alert';
import {
    requestGetChargings,
    requestDestroyCharging,
    requestCallbackAllChargings,
    requestCallbackAloneCharging,
} from '~/services/charging';

function Chargings() {
    const [pages, setPages] = useState(1);
    const [chargings, setChargings] = useState([]);

    const [index, setIndex] = useState(null);
    const [charging, setCharging] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const [value, setValue] = useState(0);
    const [amountVal, setAmountVal] = useState(0);
    const [declaredValue, setDeclaredValue] = useState(0);

    const [searchParams, setSearchParams] = useSearchParams();

    const { currentUser } = useSelector((state) => state.auth);
    const { currentCharging } = useSelector((state) => state.charging);

    const page = searchParams.get('page');

    const [code, setCode] = useState(searchParams.get('code') || '');
    const [telco, setTelco] = useState(searchParams.get('telco') || '');
    const [amount, setAmount] = useState(searchParams.get('amount') || '');
    const [serial, setSerial] = useState(searchParams.get('serial') || '');
    const [status, setStatus] = useState(searchParams.get('status') || 'all');
    const [partner, setPartner] = useState(searchParams.get('partner') || '');
    const [dateEnd, setDateEnd] = useState(searchParams.get('date_end') || '');
    const [dateStart, setDateStart] = useState(searchParams.get('date_start') || '');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!currentCharging) return;

        setValue(value + currentCharging.value);
        setChargings([currentCharging, ...chargings]);
        setAmountVal(amountVal + currentCharging.amount);
        setDeclaredValue(declaredValue + currentCharging.declared_value);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCharging]);

    useEffect(() => {
        document.title = 'Danh sách thẻ đã nạp - Quản trị website';

        const fetch = async () => {
            dispatch(startLoading());

            let objectSearch = {};
            objectSearch.page = page || 1;

            if (telco) {
                objectSearch.telco = telco;
            }
            if (amount) {
                objectSearch.amount = amount;
            }
            if (status) {
                objectSearch.status = status;
            }
            if (partner) {
                objectSearch.partner = partner;
            }
            if (dateStart) {
                objectSearch.date_start = dateStart;
            }
            if (dateEnd) {
                objectSearch.date_end = dateEnd;
            }
            if (code) {
                objectSearch.code = code;
            }
            if (serial) {
                objectSearch.serial = serial;
            }

            const result = await requestGetChargings(objectSearch);

            dispatch(stopLoading());
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutSuccess());
                navigate(routes.login);
            } else if (result.status === 200) {
                setChargings(result.data);
                setPages(result.pages);

                setValue(result.value);
                setAmountVal(result.amount);
                setDeclaredValue(result.declared_value);
            } else {
                alertError(result.error);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    // Tìm kiếm thẻ cào theo từng điều kiện
    const handleSearchCharging = async () => {
        dispatch(startLoading());

        let objectSearch = {};
        objectSearch.page = 1;

        if (telco) {
            objectSearch.telco = telco;
        }
        if (amount) {
            objectSearch.amount = amount;
        }
        if (status) {
            objectSearch.status = status;
        }
        if (partner) {
            objectSearch.partner = partner;
        }
        if (dateStart) {
            objectSearch.date_start = dateStart;
        }
        if (dateEnd) {
            objectSearch.date_end = dateEnd;
        }
        if (code) {
            objectSearch.code = code;
        }
        if (serial) {
            objectSearch.serial = serial;
        }

        setSearchParams(objectSearch);

        const result = await requestGetChargings(objectSearch);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(routes.login);
        } else if (result.status === 200) {
            setChargings(result.data);
            setPages(result.pages);

            setValue(result.value);
            setAmountVal(result.amount);
            setDeclaredValue(result.declared_value);
        } else {
            alertError(result.error);
        }
    };

    // Hàm cập nhật thẻ chi tiết
    const callbackUpdateCharging = (type, data) => {
        const cloneChargings = [...chargings];

        let result = [];
        if (type === 'delete') {
            result = cloneChargings.filter((charging) => charging._id !== data);
        } else {
            result = cloneChargings.map((charging) => {
                if (charging._id === data._id) {
                    return data;
                }

                return charging;
            });
        }

        setChargings(result);
    };

    // Hàm callback 1 thẻ
    const handleCallBackAloneCharging = async (charging) => {
        if (!charging._id) {
            return alertError('Thông tin thẻ này không tồn tại');
        }

        dispatch(startLoading());
        const result = await requestCallbackAloneCharging(charging._id);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(routes.login);
        } else if (result.status === 200) {
            const cloneChargings = [...chargings];

            const check = cloneChargings.map((charging) => {
                const { status, message, partner_id, ...other } = charging;
                if (charging._id === result.data._id) {
                    return {
                        ...other,
                        status: result.data.status,
                        message: result.data.message,
                        request: result.data.request,
                        partner_id: {
                            partner_name: result.data.partner_name,
                        },
                    };
                }

                return charging;
            });

            setChargings(check);
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    // Hàm xóa thẻ
    const handleShowDestroyCharging = async (charging, index) => {
        setIndex(index);
        setShowDelete(true);
        setCharging(charging);
    };

    const handleConfirmDestroyCharging = async () => {
        if (!charging._id) {
            return alertError('Dữ liệu thẻ cào này không tồn tại');
        }

        dispatch(startLoading());
        const result = await requestDestroyCharging(null, charging._id);

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(routes.login);
        } else if (result.status === 200) {
            setShowDelete(false);
            const cloneChargings = [...chargings];
            cloneChargings.splice(index, 1);

            setChargings(cloneChargings);
            alertSuccess(result.message);
        } else {
            alertError(result.error);
        }
    };

    // Hàm callback nhiều thẻ đang chờ xử lý
    const handleCallBackAllCharings = async () => {
        alertSelected('XỬ LÝ TẤT CẢ THẺ?', 'Mỗi lần Callback chỉ được 5 thẻ tránh SPAM').then(async (result) => {
            if (result.isConfirmed) {
                dispatch(startLoading());
                const result = await requestCallbackAllChargings();

                dispatch(stopLoading());
                if (result.status === 401 || result.status === 403) {
                    dispatch(logoutSuccess());
                    navigate(routes.login);
                } else if (result.status === 200) {
                    const cloneChargings = [...chargings];
                    const check = cloneChargings.map((charging) => {
                        const { status, message, request, partner_id, ...other } = charging;
                        const newItem = result.data.find(function (item) {
                            return item._id === charging._id;
                        });

                        if (newItem) {
                            return {
                                ...other,
                                status: newItem.status,
                                message: newItem.message,
                                request: newItem.request,
                                partner_id: {
                                    partner_name: newItem.partner_name,
                                },
                            };
                        }

                        return charging;
                    });
                    setChargings(check);
                    alertSuccess(result.message);
                } else {
                    alertError(result.error);
                }
            }
        });
    };

    // Hàm xóa thẻ nghi spam
    const handleDestroyMuch = async () => {
        alertSelected('XÓA THẺ NGHI SPAM?', 'Thẻ nghi spam là thẻ đã được phân loại sẵn').then(async (response) => {
            if (response.isConfirmed) {
                dispatch(startLoading());
                const result = await requestDestroyCharging('delete', null);

                dispatch(stopLoading());
                if (result.status === 401 || result.status === 403) {
                    dispatch(logoutSuccess());
                    navigate(routes.login);
                } else if (result.status === 200) {
                    const cloneChargings = [...chargings];

                    const result = cloneChargings.filter((charging) => charging.status !== 101);
                    setChargings(result);
                    alertSuccess(result.message);
                } else {
                    alertError(result.error);
                }
            }
        });
    };

    // Get checked
    const handleGetChecked = async () => {
        dispatch(startLoading());
        const result = await requestGetChargings({ type: 'checked' });

        dispatch(stopLoading());
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutSuccess());
            navigate(routes.login);
        } else if (result.status === 200) {
            setChargings(result.data);

            setValue(result.value);
            setAmountVal(result.amount);
            setDeclaredValue(result.declared_value);
        } else {
            alertError(result.error);
        }
    };

    return (
        <div className="wrapper">
            <div className="header">
                <Row>
                    <PageTitle name="Thống kê đổi thẻ" />

                    <Col xl={7}>
                        <div className="float-right">
                            <Button variant="danger" className="mt-xl-5 mt-0 mr-2" onClick={handleDestroyMuch}>
                                Xóa thẻ nghi SPAM
                            </Button>
                            <Link to={routes.statistic + routes.chargings + routes.callback}>
                                <Button variant="dark" className="mt-xl-5 mt-0 mr-2">
                                    <FontAwesomeIcon icon={faGears} />
                                    <span> Callback</span>
                                </Button>
                            </Link>
                            <Link to={routes.history + routes.callback}>
                                <Button variant="warning" className="mt-xl-5 mt-3 mt-md-0">
                                    <FontAwesomeIcon icon={faRotate} />
                                    <span> Lịch xử callback</span>
                                </Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col md={12} className="px-0 px-md-2">
                        <Card>
                            <Card.Header>
                                <Row className="justify-content-end">
                                    <Col md="1-5" className="px-0 col-12">
                                        <select className="form-control" value={telco} onChange={(e) => setTelco(e.target.value)}>
                                            <option value="">Loại thẻ</option>
                                            <option value="VIETTEL">VIETTEL</option>
                                            <option value="GARENA">GARENA</option>
                                            <option value="MOBIFONE">MOBIFONE</option>
                                            <option value="VINAPHONE">VINAPHONE</option>
                                        </select>
                                    </Col>
                                    <Col md="1-5" className="px-0 col-12">
                                        <select className="form-control" value={amount} onChange={(e) => setAmount(e.target.value)}>
                                            <option value="">Mệnh giá</option>
                                            <option value="50000">50.000đ</option>
                                            <option value="100000">100.000đ</option>
                                            <option value="200000">200.000đ</option>
                                            <option value="300000">300.000đ</option>
                                            <option value="500000">500.000đ</option>
                                        </select>
                                    </Col>
                                    <Col md="1-5" className="px-0 col-12">
                                        <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                                            <option value="all">Tất cả</option>
                                            <option value="3">Thẻ lỗi</option>
                                            <option value="102">Lỗi API</option>
                                            <option value="1">Thẻ đúng</option>
                                            <option value="101">Chờ xử lý</option>
                                            <option value="99">Đang xử lý</option>
                                            <option value="2">Sai mệnh giá</option>
                                        </select>
                                    </Col>
                                    <Col md="1-5" className="px-0 col-12">
                                        <select className="form-control" value={partner} onChange={(e) => setPartner(e.target.value)}>
                                            <option value="">Đối tác</option>
                                            <option value="Doithe">Doithe</option>
                                            <option value="Webthe">Webthe</option>
                                            <option value="Trumthe">Trumthe</option>
                                            <option value="Thegiare">Thegiare</option>
                                            <option value="Thesieure">Thesieure</option>
                                            <option value="Thenhanh">Thenhanh</option>
                                        </select>
                                    </Col>
                                    <Col md="1-5" className="px-0 col-12">
                                        <input
                                            className="form-control"
                                            type="date"
                                            width="100%"
                                            value={dateStart}
                                            onChange={(e) => setDateStart(e.target.value)}
                                        />
                                    </Col>
                                    <Col md="1-5" className="px-0 col-12">
                                        <input
                                            className="form-control"
                                            type="date"
                                            width="100%"
                                            value={dateEnd}
                                            onChange={(e) => setDateEnd(e.target.value)}
                                        />
                                    </Col>
                                    <Col md="1-5" className="px-0 col-12">
                                        <input
                                            className="form-control"
                                            placeholder="Mã thẻ"
                                            value={code}
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                    </Col>
                                    <Col md="1-5" className="px-0 col-12">
                                        <input
                                            className="form-control"
                                            placeholder="Serial thẻ"
                                            value={serial}
                                            onChange={(e) => setSerial(e.target.value)}
                                        />
                                    </Col>
                                    <div className="input-group-append mt-2 mt-md-0">
                                        <Button variant="warning" title="Tìm kiếm" className="mr-2 mr-md-0" onClick={handleSearchCharging}>
                                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                                            <span>Lọc</span>
                                        </Button>
                                        <Button
                                            variant="success"
                                            title="Callback toàn bộ thẻ chờ xử lý"
                                            className="mr-2 mr-md-0"
                                            onClick={handleCallBackAllCharings}
                                        >
                                            <FontAwesomeIcon icon={faDownload} />
                                            <span>Callback all</span>
                                        </Button>
                                        {currentUser?.status && (
                                            <Button variant="secondary" onClick={handleGetChecked}>
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                                <span>Checked</span>
                                            </Button>
                                        )}
                                    </div>
                                </Row>
                            </Card.Header>

                            <Card.Body>
                                <div className="table-responsive">
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>TT</th>
                                                <th>Mạng</th>
                                                <th>Thông tin thẻ</th>
                                                <th>NCC</th>
                                                <th>Khách hàng</th>
                                                <th>Khai báo</th>
                                                <th>Thực</th>
                                                <th>Nhận</th>
                                                <th>Gửi / Duyệt</th>
                                                <th>Mã</th>
                                                <th>Xử lý</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {chargings.length > 0 ? (
                                                chargings.map((charging, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <Badge
                                                                bg={
                                                                    charging.status === 1
                                                                        ? 'success'
                                                                        : charging.status === 2
                                                                        ? 'info'
                                                                        : charging.status === 99 ||
                                                                          charging.status === 101 ||
                                                                          charging.status === 103 ||
                                                                          charging.status === 104
                                                                        ? 'warning'
                                                                        : 'danger'
                                                                }
                                                            >
                                                                {charging.message}
                                                                {charging.checked && (
                                                                    <FontAwesomeIcon className="ml-2" icon={faCheckCircle} />
                                                                )}
                                                            </Badge>
                                                        </td>
                                                        <td>{charging.telco}</td>
                                                        <td>
                                                            <span>M:{charging.code}</span>
                                                            <br />
                                                            <span>S:{charging.serial}</span>
                                                        </td>
                                                        <td>
                                                            {charging.partner_id ? (
                                                                <Fragment>{charging.partner_id.partner_name}</Fragment>
                                                            ) : (
                                                                <Fragment>Không</Fragment>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {charging.user_id ? (
                                                                <Fragment>
                                                                    <span>
                                                                        {charging.user_id.account_garena.username ? 'GARENA' : 'FREE FIRE'}
                                                                    </span>
                                                                    <br />
                                                                    <span>
                                                                        {charging.user_id.account_freefire.nickname ||
                                                                            charging.user_id.account_garena.username}
                                                                    </span>
                                                                </Fragment>
                                                            ) : (
                                                                <Fragment>Không</Fragment>
                                                            )}
                                                        </td>
                                                        <td>{convertCurrency(charging.declared_value)}</td>
                                                        <td>{convertCurrency(charging.value)}</td>
                                                        <td>{convertCurrency(charging.amount)}</td>
                                                        <td>
                                                            <span>{moment(charging.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                            <br />
                                                            <span className="text-success">
                                                                {charging.approved_at
                                                                    ? moment(charging.approved_at).format('YYYY-MM-DD HH:mm:ss')
                                                                    : ''}
                                                            </span>
                                                        </td>

                                                        <td>{charging.request}</td>
                                                        <td>
                                                            <Button
                                                                variant="success"
                                                                size="sm"
                                                                onClick={() => {
                                                                    setShowDetail(true);
                                                                    setCharging(charging);
                                                                }}
                                                            >
                                                                Chi tiết
                                                            </Button>
                                                            {(charging.status === 101 ||
                                                                charging.status === 102 ||
                                                                charging.status === 103) && (
                                                                <Fragment>
                                                                    <Button
                                                                        variant="info"
                                                                        size="sm"
                                                                        className="ml-2"
                                                                        title="Xử lý"
                                                                        onClick={() => handleCallBackAloneCharging(charging)}
                                                                    >
                                                                        Callback
                                                                    </Button>
                                                                    <Button
                                                                        variant="danger"
                                                                        size="sm"
                                                                        className="ml-2"
                                                                        title="Xóa"
                                                                        onClick={() => handleShowDestroyCharging(charging, index)}
                                                                    >
                                                                        <FontAwesomeIcon icon={faTrashCan} />
                                                                    </Button>
                                                                </Fragment>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={13}>Không có dữ liệu</td>
                                                </tr>
                                            )}
                                        </tbody>
                                        {(!page || page === '1') && (
                                            <tfoot>
                                                <tr>
                                                    <th colSpan={5} className="text-right">
                                                        Tổng số:
                                                    </th>
                                                    <th>{convertCurrency(declaredValue)}</th>
                                                    <th>{convertCurrency(value)}</th>
                                                    <th>{convertCurrency(amountVal)}</th>
                                                    <th colSpan={5} />
                                                </tr>
                                            </tfoot>
                                        )}
                                    </Table>
                                </div>
                            </Card.Body>

                            {pages > 1 && (
                                <Card.Footer>
                                    <Row>
                                        <Col xl={12}>
                                            <div className="float-right">
                                                <CusPagination
                                                    page={page}
                                                    pages={pages}
                                                    setSearchParams={setSearchParams}
                                                    params={{
                                                        ...(telco && { telco }),
                                                        ...(amount && { amount }),
                                                        ...(status && { status }),
                                                        ...(partner && { partner }),
                                                        ...(dateStart && { date_start: dateStart }),
                                                        ...(dateEnd && { date_end: dateEnd }),
                                                        ...(code && { code }),
                                                        ...(serial && { serial }),
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>

            {showDetail && charging && (
                <ModalCharging show={showDetail} setShow={setShowDetail} charging={charging} callback={callbackUpdateCharging} />
            )}
            {showDelete && charging && (
                <ModalDestroy show={showDelete} setShow={setShowDelete} name={`${charging.code}`} onClick={handleConfirmDestroyCharging} />
            )}
        </div>
    );
}

export default Chargings;
