import { createSlice } from '@reduxjs/toolkit';

const statsReducer = createSlice({
    name: 'charging',
    initialState: {
        currentCharging: null,
    },
    reducers: {
        setSocketCharging: (state, { payload }) => {
            state.currentCharging = payload;
        },
    },
});

export const { setSocketCharging } = statsReducer.actions;

export default statsReducer.reducer;
